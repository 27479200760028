import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "LandingPage",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/LandingPage"),
  },
  {
    path: "/login",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/LoginView"),
  },
  {
    path: "/registration",
    name: "registration",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/RegistrationView"),
  },
  {
    path: "/payment/success",
    name: "SuccessPayment",
    component: () =>
      import(/* webpackChunkName: "about" */ "../pages/SuccessPayment"),
  },
  {
    path: "/refund-policy",
    name: "RefundPolicy",
    component: () =>
      import(/* webpackChunkName: "about" */ "../pages/Policy/RefundPolicy"),
  },
  {
    path: "/terms-and-conditions",
    name: "terms-and-conditions",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../pages/Policy/TermsAndConditions"
      ),
  },
  {
    path: "/privacy-policy",
    name: "privacy-policy",
    component: () =>
      import(/* webpackChunkName: "about" */ "../pages/Policy/privacy-policy"),
  },
  {
    path: "/terms-of-purchase",
    name: "terms-of-purchase",
    component: () =>
      import(/* webpackChunkName: "about" */ "../pages/Policy/TermsofPurchase"),
  },
  {
    path: "/membership",
    name: "membership",
    component: () =>
      import(/* webpackChunkName: "about" */ "../pages/Policy/MembershipView"),
  },
  {
    path: "/membership-cancellation",
    name: "membership-cancellation",
    component: () =>
      import(/* webpackChunkName: "about" */ "../pages/Policy/MembershipCancellation"),
  },
  {
    path: "/app",
    component: () => import(/* webpackChunkName: "about" */ "../views/AppView"),
    children: [
      {
        path: "/",
        name: "WorkoutBuilder",
        component: () =>
          import(/* webpackChunkName: "about" */ "../pages/WorkoutBuilder"),
      },
      {
        path: "/guides",
        name: "FitnessGuides",
        component: () =>
          import(/* webpackChunkName: "about" */ "../pages/FitnessGuides"),
      },
      {
        path: "/account",
        name: "AccountSettings",
        component: () =>
          import(/* webpackChunkName: "about" */ "../pages/AccountSettings"),
      },
      {
        path: "/training",
        name: "TrainingWrp",
        component: () =>
          import(/* webpackChunkName: "about" */ "../pages/TrainingWrp"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

router.beforeEach((to, from, next) => {
  let user = JSON.parse(localStorage.getItem("user"));
  if (to.path == "/refund-policy") {
    next();
    return;
  }
  if (to.path == "/subscription-terms-ios") {
    next();
    return;
  }
  if (to.path == "/terms-and-conditions") {
    next();
    return;
  }
  if (to.path == "/privacy-policy") {
    next();
    return;
  }
  if (to.path == "/membership-cancellation") {
    next();
    return;
  }
  if (to.path == "/terms-of-purchase") {
    next();
    return;
  }
  if (to.path == "/membership") {
    next();
    return;
  }
  if (!user && to.path == "/payment/success") {
    next();
    return;
  }
  if (
    !user &&
    (to.path === "/login" || to.path === "/registration" || to.path === "/")
  ) {
    next();
    return;
  }

  if (
    (to.path === "/login" || to.path === "/registration" || to.path === "/") &&
    user
  ) {
    next("/app");
    return;
  }

  next();
});

export default router;
